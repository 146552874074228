<template>
  <div class="calendar">
    <a-calendar
      v-model="value"
      :fullscreen="false"
      @change="onDateSelect"
      :locale="locale"
    />
  </div>
</template>
<script >
 import moment from 'moment';
 import 'moment/locale/es';
 moment.locale('es');

const locale = {
  lang: {
    locale: "es_ES",
    placeholder: "Seleccione la fecha",
    rangePlaceholder: ["Fecha inicial", "Fecha final"],
    today: "Hoy",
    now: "Ahora",
    backToToday: "Ayer",
    ok: "OK",
    clear: "Clear",
    month: "Mes",
    year: "Año",
    timeSelect: "Select time",
    dateSelect: "Select date",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Previous month (PageUp)",
    nextMonth: "Next month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Last decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
  },
  timePickerLocale: {
    placeholder: "Select time",
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM",
};
export default {
  data() {
    return {
      locale,
      value: "",
    };
  },
  created() {},
  methods: {
    onDateSelect() {
      this.$emit("selected-date", this.value.format("YYYY-MM-DD"));
    },
  },
};
</script>
<style >
.calendar .ant-fullcalendar-header {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}
.calendar .ant-fullcalendar-header .ant-radio-group {
  display: flex;
}
</style>